$color-white: #ffffff;
$color-black: #000000;
$color-grey: #808080;
$color-insta: #ffd561;
$color-twitter: #55acee;
$color-facebook: #3b5998;
$color-linkedin: #007bb5;
$color-header: #00237a80;
$color-table-primary: #00e1ff65;
$color-table-secondary:  #00ff9d;
$color-table-border: #9796964d;