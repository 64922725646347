@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
nav {
  max-width: 960px;
  -webkit-mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 25%, #ffffff 75%, rgba(255, 255, 255, 0) 100%);
          mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 25%, #ffffff 75%, rgba(255, 255, 255, 0) 100%);
  margin: 0 auto;
  padding-top: 1rem;
  background-color: transparent;
  font-family: "Muli", sans-serif; }
  nav ul, nav li {
    display: inline-block; }
    nav ul a, nav li a {
      padding-left: 18px;
      padding-right: 18px;
      color: #00237a80;
      font-size: 1em;
      -webkit-animation-duration: 5s;
              animation-duration: 5s;
      transition: ease-in 1s; }
      nav ul a :hover, nav li a :hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
        background: rgba(255, 255, 255, 0.1);
        color: rgba(69, 100, 180, 0.7); }

.newSup {
  font-size: 12px;
  color: gold; }

.img {
  width: 200px; }
  @media screen and (max-width: 720px) {
    .img {
      margin-right: 60%; } }

.name {
  font-size: 5em;
  font-weight: 700;
  color: #ffffff;
  text-align: left;
  margin-left: 1rem; }

.post-container {
  font-size: 0.3em;
  color: #ffffff;
  margin-top: 1rem;
  font-weight: 400;
  opacity: 0.8; }

.container {
  max-width: 1600px !important;
  padding-right: 0px !important;
  padding-left: 4rem !important; }

.intro {
  background-color: #000000;
  background: #000000;
  color: #ffffff;
  text-align: left;
  opacity: 0.9;
  font-size: 1em;
  padding-bottom: 2%;
  padding-right: 3%;
  padding-left: 3%; }

.bg {
  padding-top: 15%;
  background: linear-Gradient(200deg, #b5fdea, #25a197, #3ff3a8, #23d5ab, #0c6cd5);
  background-size: 400% 400%;
  background-repeat: no-repeat;
  height: 100vh;
  background-position: center;
  -webkit-animation: Grad 10s ease infinite;
  animation: Grad 10s ease infinite; }

.abt {
  margin-top: 0%;
  padding-top: 3%;
  padding-left: 3%; }

.eul,
.eli {
  text-align: left; }

.ul2 {
  font-family: "Muli", sans-serif;
  margin-top: 1%; }

.extras {
  padding-bottom: 0px;
  padding-top: 2%; }

td {
  width: 90px; }

.footer {
  background: #000000;
  padding-top: 30px;
  color: #ffffff;
  padding-bottom: 10px; }

.email {
  color: #ffffff; }

.icon-bars {
  padding: 1rem;
  transition: all 0.3s ease;
  color: #ffffff;
  font-size: 1em;
  margin: 0.5rem; }
  .icon-bars .insta,
  .icon-bars .linkedin,
  .icon-bars .facebook,
  .icon-bars .github,
  .icon-bars .twitter {
    color: #ffffff;
    margin: 2px;
    padding: 8px;
    border-radius: 25%; }

.icon-bar {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }
  .icon-bar a {
    display: block;
    padding: 1rem;
    transition: all 0.3s ease;
    color: #ffffff;
    border-radius: 50%;
    font-size: 1em;
    line-height: 20px;
    margin: 1rem; }
  .icon-bar :hover {
    background-color: #000000; }
  @media screen and (max-width: 720px) {
    .icon-bar {
      display: none; } }

.insta {
  background: #ffd561; }

.twitter {
  background: #55acee; }

.facebook {
  background: #3b5998; }

.linkedin {
  background: #007bb5; }

.footlb {
  padding-right: 1rem; }

.name {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 3s;
          animation-duration: 3s; }
  .name .made {
    opacity: 0.5; }

body {
  min-width: 700px;
  font-family: "Muli", sans-serif !important;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes Grad {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes Grad {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.gal {
  padding-left: 12rem;
  padding-right: 12rem;
  padding-top: 1rem;
  text-align: left; }
  .gal .sub-text {
    margin-left: 2em; }

.ReactGridGallery {
  background: gray; }

.head, .txt {
  font-family: "Muli", sans-serif;
  text-align: left; }
  .head.txt, .txt.txt {
    margin-left: 2em; }
  .head .link, .txt .link {
    color: grey; }

.table {
  padding-left: 1rem;
  padding-right: 1rem; }

body {
  min-width: 700px;
  font-family: "Muli", sans-serif !important;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.tbl-content {
  overflow-x: auto;
  background: #00e1ff65;
  font-family: "Muli", sans-serif; }

.other, .others {
  border-right: 4px solid #9796964d;
  border-bottom: 1px solid #9796964d;
  border-top: 1px solid #9796964d;
  color: #000000;
  text-align: left;
  font-weight: 400; }

.other {
  background: #ffffff;
  font-size: 1em;
  text-transform: uppercase; }

.others {
  font-size: 0.8em; }
  .others:nth-child(even) {
    background: #00ff9d; }

.pdf {
  width: 100%;
  height: 100%;
  height: 100vh; }

