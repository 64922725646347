@import '../Partials/fonts';
@import '../Partials/colors';

nav {
	max-width: 960px;
	mask-image: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		#ffffff 25%,
		#ffffff 75%,
		rgba(255, 255, 255, 0) 100%
	);
	margin: 0 auto;
	padding-top: 1rem;
	background-color: transparent;
	font-family: $primary-font;
	ul, li {
		display: inline-block;
		a {
			padding-left: 18px;
			padding-right: 18px;
			color: $color-header;
			font-size: $font-size-normal;
			animation-duration: 5s;
			transition: ease-in 1s;
			:hover {
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.1),
					inset 0 0 1px rgba(255, 255, 255, 0.6);
				background: rgba(255, 255, 255, 0.1);
				color: rgba(69, 100, 180, 0.7);
			}
		}
	}
}

.newSup {
	font-size: 12px;
	color: gold;
}
