@import '../Partials/fonts';
@import '../Partials/colors';

body {
	min-width: 700px;
	font-family: $primary-font !important;
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
	animation-duration: 1s;
}


.tbl-content {
    overflow-x: auto;
    background: $color-table-primary;
    font-family: $primary-font;
}

.other, .others {
    border-right: 4px solid $color-table-border;
    border-bottom: 1px solid $color-table-border;
    border-top: 1px solid $color-table-border;
    color: $color-black;
    text-align: left;
    font-weight: 400;
}

.other {
    background: $color-white;
    font-size: $font-size-normal;
    text-transform: uppercase;
}

.others {
    font-size: $font-size-table;

    &:nth-child(even) {
        background: $color-table-secondary;
    }
}
