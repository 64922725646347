.gal {
	padding-left: 12rem;
	padding-right: 12rem;
	padding-top: 1rem;
	text-align: left;

	.sub-text {
		margin-left: 2em;
	}
}

.ReactGridGallery {
	background: gray;
}
